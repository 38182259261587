import VueRouter from 'vue-router';
import ProductIntroduction from '../components/ProductIntroduction';
import RecentActivities from '../components/RecentActivities.vue';
import RecentActivitiesDetail from '../components/RecentActivitiesDetail.vue';
import Recharge from '../components/Recharge.vue';
import Login from '../components/Login.vue';
import Register from '../components/Register.vue';
import News from '../components/News.vue';
import Abouts from '../components/Abouts.vue';


const routes = [
    {
        path: '/',
        name: 'ProductIntroduction',
        component: ProductIntroduction,
    },
    {
        path: '/activities',
        name: 'Activities',
        component: RecentActivities,
    },
    {
        path: '/activitiesDetail/:id',
        name: 'RecentActivitiesDetail',
        component: RecentActivitiesDetail,
    },
    {
        path: '/recharge',
        name: 'Recharge',
        component: Recharge,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',
        name: Register,
        component: Register,
    },
    {
        path: '/news',
        name: News,
        component: News,
    },
    {
        path: '/about',
        name: Abouts,
        component: Abouts
    }

];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
