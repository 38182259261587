<template>
    <div class="reg-container">
        <h1>北京多优乐科技有限公司用户注册</h1>
	
	<div class="connect">
		<p>以下信息我们绝对不会外泄</p>
	</div>
	
	<div class="login_form">
					<form action="" id="regist_form" method="">
						<div class="form-group">
							<label for="j_username" class="t" style = "position: absolute;left: -73px;top: 14px;">邮 箱：</label> <input id="username" value="" name="username" type="text"
								onblur="checkUsername();" class="password form-control x319 in"><span id="usernamemsg" class="err" style = "position: absolute;width: 200px;top: 14px; left: 270px; color: red;"></span>
						</div>
						<div class="form-group">
							<label for="j_password" class="t" style = "position: absolute;left: -73px;top: 79px;">密 码：</label> <input id="password" value="" name="password" type="password"
								onblur="checkPassword();" class="password form-control x319 in"><span id="passwordmsg" class="err" style = "position: absolute;width: 200px;top: 79px; left: 270px; color: red;"></span>
						</div>
						<div class="form-group">
							<label for="j_password" class="t" style = "position: absolute;left: -73px;top: 144px;">确认密码：</label> <input id="repassword" value="" name="repassword" type="password"
								onblur="checkRepassword();" class="password form-control x319 in"> <span id="repasswordmsg"
								class="err" style = "position: absolute;width: 200px;top: 144px; left: 270px; color: red;"></span>

						</div>
						<div class="form-group">
							<label for="j_password" class="t" style = "position: absolute;left: -73px;top: 215px;">姓 名：</label> <input id="nick" value="" name="nick" type="text"
								onblur="checkName();" class="password form-control x319 in"> <span id="nameerrormsg" class="err" style = "position: absolute;width: 200px;top: 215px; left: 270px; color: red;"></span>

						</div>
						<div class="form-group">
							<label for="j_password" class="t" style = "position: absolute;left: -73px;top: 278px;">身份证：</label> <input id="idcard" value="" name="idcard" type="text"
								onblur="checkIdcard();" class="form-control x319 in" maxlength="18"> <span id="idcardmsg" class="err" style = "position: absolute;width: 200px;top: 278px; left: 270px; color: red;"></span>

						</div>

						<div class="form-group">
							<label for="j_password" class="t" style = "position: absolute;left: -73px;top: 343px;">手机号：</label> <input id="mobile" value="" name="mobile" type="text"
								onblur="checkMobile();" class="form-control x319 in"> <span id="mobilemsg" class="err" style = "position: absolute;width: 200px;top: 343px; left: 270px; color: red;"></span>
						</div>
						
						
						
						<div class="form-group space">
							<label class="t"></label><!--disabled="disabled" -->
							<button type="button" id="submit_btn" name="submit" value="submit" onclick="dosubmit();"
								class="btn btn-primary btn-lg">注 册</button>
							<input type="reset" value="重 置" class="btn btn-default btn-lg">
						</div>
					</form>
				</div>
        </div>
</template>
    
<script>
    export default {
        name:"Reg"
    }

//     window.onload = function(){
// 	$(".connect p").eq(0).animate({"left":"0%"}, 600);
// 	$(".connect p").eq(1).animate({"left":"0%"}, 400);
// };
//jquery.validate表单验证
    $(document).ready(function(){

    console.log("执行了");

    $('#usernamemsg').html('');
	$('#passwordmsg').html('');
	$('#repasswordmsg').html('');
	$('#nameerrormsg').html('');
	$('#mobilemsg').html('');
	var regerrormsg="";

     function checkUsername() {
        var vusername = $('#username').val();
		var reg =  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,30})+$/;
		if(!reg.test(vusername)){
			regerrormsg="请输入正确的邮箱";
			$('#usernamemsg').html(regerrormsg);
		}else{
			$('#usernamemsg').html('');
			regerrormsg = "";
		}	 
     }
     window.checkUsername = checkUsername;

     function checkPassword(){
		var value = $('#password').val();
		var reg = /^[a-zA-Z0-9]{1,32}$/;
		if(!reg.test(value)){
			regerrormsg="请输入正确的密码";
			$('#passwordmsg').html(regerrormsg);	
		}else{
			$('#passwordmsg').html('');	
			regerrormsg = "";
		}	
	}

    window.checkPassword = checkPassword;
	
	function checkRepassword(){
		var value = $('#password').val();
		var value2 = $('#repassword').val();
		console.log(value+value2);
		if(value==value2){
			$('#repasswordmsg').html('');
			regerrormsg = "";
		}else{
			regerrormsg="两次密码不一致";
			$('#repasswordmsg').html(regerrormsg);	
		}	
	}
	
    window.checkRepassword = checkRepassword;


	function checkName(){
		var value = $('#nick').val();
		var reg =   /^[\u4e00-\u9fa5]{2,5}$/;
		if(!reg.test(value)){
			regerrormsg="请输入正确的姓名";
			$('#nameerrormsg').html(regerrormsg);
		}else{
			regerrormsg = "";
			$('#nameerrormsg').html('');
		}	
	}

    window.checkName = checkName;

	function checkIdcard(){
		var value = $('#idcard').val();
		var reg =/^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
		if(!reg.test(value)){
			regerrormsg="请输入正确的身份证";
			$('#idcardmsg').html(regerrormsg);	
		}else{
			regerrormsg = "";
			$('#idcardmsg').html('');	
			// $('#idcardmsg').html('');
			// 	var vnick = $('#nick').val();
			// 	$.ajax({
			// 	cache: false,
			// 	type: "POST",
			// 	url:'http://27.50.136.66:9010/tyxbweb/CheckIdcard',
			// 	data:{
			// 		idcard:value,
			// 		nick:vnick
			// 	},// 你的formid
			// 	async: true,
			// 	dataType :"json",
			// 	success: function(data) {
			// 		console.info(data);
			// 		if(data.success){
			// 			alert("身份证匹配");
			// 			regerrormsg="";
			// 			$('#idcardmsg').html(regerrormsg);	
			// 			//window.location.href="http://localhost:80/index.html";
			// 		}else{
			// 			alert("身份证不匹配");
			// 			regerrormsg="身份证信息不匹配";
			// 			$('#idcardmsg').html(regerrormsg);	
			// 		}
			// 	}
			// });
			
		}	
	}

    window.checkIdcard = checkIdcard;

	
	function checkMobile(){
		var value = $('#mobile').val();
		var reg =/^1[23456789]\d{9}$/;
		if(!reg.test(value)){
			regerrormsg="请输入正确的手机号";
			$('#mobilemsg').html(regerrormsg);	
		}else{
			$('#mobilemsg').html('');
			regerrormsg = "";
		}	
	}

    window.checkMobile = checkMobile;


	  function dosubmit(){
		 checkUsername();
		 if (regerrormsg != ''){
			// alert(regerrormsg);
			return false;
		 }
         checkPassword();
		 if (regerrormsg != ''){
			// alert(regerrormsg);
			return false;
		 }
         checkRepassword();
		 if (regerrormsg != ''){
			// alert(regerrormsg);
			return false;
		 }
	     checkName();
		 if (regerrormsg != ''){
			// alert(regerrormsg);
			return false;
		 }
		 checkIdcard();
		 if (regerrormsg != ''){
			// alert(regerrormsg);
			return false;
		 }
         checkMobile();
		 if (regerrormsg != ''){
			// alert(regerrormsg);
			return false;
		 }

		 alert("请输入正确密码");
         return false;

		// if(regerrormsg!=''){
		// 	alert("校验未通过"+regerrormsg);
		// 	return false;
		// }else{
        //     alert("请输入正确密码");
        //     return false;
			// $.ajax({
			// 	cache: true,
			// 	type: "POST",
			// 	url:'http://27.50.136.66:9010/tyxbweb/UserServlet?command=regist',
			// 	data:$('#regist_form').serialize(),// 你的formid
			// 	async: false,
			// 	dataType :"json",
			// 	success: function(data) {
			// 		console.info(data);
			// 		//var obj = jQuery.parseJSON(data);
			// 		//console.info(obj);
			// 		if(data.success){
			// 			alert("注册成功,即将跳转到登陆界面");
			// 			//window.location.href="http://localhost:80/index.html";
			// 			window.location.href="login.html";//注册成功后跳转的页面
			// 		}else{
			// 			alert("注册失败请刷新后再试");
			// 		}
			// 	}
			// });
		// }       
    }

    window.dosubmit = dosubmit;


	//注册表单验证
	$("#registerForm").validate({
		rules:{
			username:{
				required:true,//必填
				minlength:3, //最少6个字符
				maxlength:32,//最多20个字符
				remote:{
					url:"http://kouss.com/demo/Sharelink/remote.json",//用户名重复检查，别跨域调用
					type:"post",
				},
			},
			password:{
				required:true,
				minlength:3, 
				maxlength:32,
			},
			email:{
				required:true,
				email:true,
			},
			confirm_password:{
				required:true,
				minlength:3,
				equalTo:'.password'
			},
			phone_number:{
				required:true,
				phone_number:true,//自定义的规则
				digits:true,//整数
			}
		},
		//错误信息提示
		messages:{
			username:{
				required:"必须填写用户名",
				minlength:"用户名至少为3个字符",
				maxlength:"用户名至多为32个字符",
				remote: "用户名已存在",
			},
			password:{
				required:"必须填写密码",
				minlength:"密码至少为3个字符",
				maxlength:"密码至多为32个字符",
			},
			email:{
				required:"请输入邮箱地址",
				email: "请输入正确的email地址"
			},
			confirm_password:{
				required: "请再次输入密码",
				minlength: "确认密码不能少于3个字符",
				equalTo: "两次输入密码不一致",//与另一个元素相同
			},
			phone_number:{
				required:"请输入手机号码",
				digits:"请输入正确的手机号码",
			},
		
		},
	});
	//添加自定义验证规则
	jQuery.validator.addMethod("phone_number", function(value, element) { 
		var length = value.length; 
		var phone_number = /^(((13[0-9]{1})|(15[0-9]{1}))+\d{8})$/ 
		return this.optional(element) || (length == 11 && phone_number.test(value)); 
	}, "手机号码格式错误"); 
});

</script>
 
<style scoped>
html {
    height: 100%;
}
body {
    height: 100%;
}
.reg-container {
    /* margin-top: 5%; */
    /* height: 1100px;
    width: 100%;
    background-image: url("./../assets/images/bg.png");
    background-repeat: repeat-y;
    background-size: 100% auto; */

    height: 1100px;
    width: 100%;
    background-image: url("./../assets/images/home/jz_bg_1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    padding-bottom: 1.667vw;
    padding-top: 6.083vw;
    text-align: center;
    color: #fff;
    padding-top: 10%;
}

a {
    text-decoration-line: none;
    color: #abc1ee;
}
 

h1 {
	font-size: 30px;
	font-weight: 700;
	text-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    color: #fff;
}

form {
	position: relative;
	width: 305px;
	margin: 15px auto 0 auto;
	text-align: center
}

input {
	width: 270px;
	height: 42px;
	line-height: 42px;
	margin-top: 25px;
	padding: 0 15px;
	background: #2d2d2d;
	background: rgba(45, 45, 45, .3);
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #3d3d3d;
	border: 1px solid rgba(255, 255, 255, .3);
	-moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .1);
	-o-transition: all .2s;
	-moz-transition: all .2s;
	-webkit-transition: all .2s;
	-ms-transition: all .2s
}

input:-moz-placeholder {
	color: #fff
}

input:-ms-input-placeholder {
	color: #fff
}

input::-webkit-input-placeholder {
	color: #fff
}

input:focus {
	outline: 0;
	-moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset, 0 2px 7px 0 rgba(0, 0, 0, .2)
}

button {
	cursor: pointer;
	width: 300px;
	height: 44px;
	margin-top: 25px;
	padding: 0;
	background: rgba(6, 127, 228, 0.71);
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	border: 0;
	-moz-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .25) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .1);
	-o-transition: all .2s;
	-moz-transition: all .2s;
	-webkit-transition: all .2s;
	-ms-transition: all .2s
}

button:hover {
	-moz-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2)
}

button:active {
	-moz-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .1) inset, 0 1px 4px 0 rgba(0, 0, 0, .1);
}

.connect {
	margin-top: 18px;
	width: 100%;
	font-size: 14px;
	text-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    color: #fff;
}

.connect p {
	position: relative;
	/* left: -140%; */
	top: 0;
	margin-top: 20px;
    color: #fff;
}

.connect a {
	display: inline-block;
	width: 32px;
	height: 35px;
	margin-top: 15px;
	-o-transition: all .2s;
	-moz-transition: all .2s;
	-webkit-transition: all .2s;
	-ms-transition: all .2s
}

.alert {
	width: 310px;
	height: 200px;
	background: #000;
	position: absolute;
	top: -40%;
	left: 50%;
	margin: -101px 0 0 -151px
}

.alert h2 {
	height: 40px;
	padding-left: 8px;
	font-size: 14px;
	background: #FF0543;
	text-align: left;
	line-height: 40px
}

.alert .alert_con {
	background: #fff;
	height: 160px
}

.alert .alert_con p {
	color: #000;
	line-height: 90px
}

.alert .alert_con .btn {
	padding: 3px 10px;
	color: #fff;
	cursor: pointer;
	background: #72D1FF;
	border: 1px solid #72D1FF;
	border-radius: 4px
}

.alert .alert_con .btn:hover {
	background: #4FB2EF;
	border: 1px solid #4FB2EF;
	border-radius: 4px
}

/* Supersized - Fullscreen Slideshow jQuery Plugin  Version : 3.2.7 */
* {
	margin: 0;
	padding: 0
}

body {
	background: #111;
	height: 100%
}

img {
	border: none
}


.box-line {
	height: 0;
	overflow: hidden;
	border-top: 1px #c2c2c2 solid;
	margin-top: 20px;
}

.register-tis {
	line-height: 42px;
	margin: 15px auto 0px;
	padding: 0 15px;
	border: 1px solid rgba(255, 255, 255, 0.38);
	background: rgba(45, 45, 45, 0.33);
	border-radius: 6px;
}

label.error {
	display: block;
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	border-radius: 0px 0px 5px 5px;
	width: 300px;
	height: 34px;
	border: 1px solid rgba(255, 255, 255, .15);
	line-height: 31px;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .1);
	background: rgba(245, 26, 26, 0.81);
	-moz-border-radius: 6px;
	margin: 0 auto;
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
select.input-lg {
  height: 46px;
  line-height: 46px;
}
textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}
.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.form-group {
  margin-bottom: 15px;
}

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }
  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

/* .form-horizontal .form-group:before */
/* .form-horizontal .form-group:after, */

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}


</style>

