<template>
    <div class="login-pag">
            <h1>北京多优乐科技有限公司用户登录</h1>	
	<div class="connect">
		<p>北京多优乐科技有限公司</p>
	</div> 
             <div class="form-wrapper">
                <form action="" method="" id="loginForm">
                    <div>
                        <input type="text" name="username" class="username" placeholder="邮箱" id="username" autocomplete="off" onblur="checkUsername();" />
                    </div>
                    <div>
                        <input type="password" name="password" class="password" placeholder="密码" id="password"
                            oncontextmenu="return false" onpaste="return false" onblur="checkPassword();" />
                    </div>
                    <button id="submit" type="button" onclick="doLogin()">登 录</button>
                </form>
                <!-- <input type="text" name="username" placeholder="用户名" class="input-item">
                    <input type="password" name="password" placeholder="密码" class="input-item"> -->
                <!-- <div class="btn">登录</div> -->
            </div>
        </div>
</template>

<!-- <script src="https://code.jquery.com/jquery-3.1.1.min.js"></script> -->
<!--背景图片自动更换-->
<!-- <script src="js/supersized.3.2.7.min.js"></script>
<script src="js/supersized-init.js"></script> -->
<!--表单验证-->
<!-- <script src="https://cdn.jsdelivr.net/npm/jquery-validation@1.19.5/dist/jquery.validate.min.js"></script> -->

<script>

// import $ from 'jquery'

export default {
    name: "Login"
}

// window.onload = function(){
// 	$(".connect p").eq(0).animate({"left":"0%"}, 600);
// 	$(".connect p").eq(1).animate({"left":"0%"}, 400);
// };
//jquery.validate表单验证
    $(document).ready(function(){

    console.log("执行了");


	//登陆表单验证
	$('#loginForm').validate({
		rules:{
			username:{
				required:true,//必填
				minlength:3, //最少6个字符
				maxlength:32,//最多20个字符
			},
			password:{
				required:true,
				minlength:3, 
				maxlength:32,
			},
		},
		//错误信息提示
		messages:{
			username:{
				required:"必须填写用户名",
				minlength:"用户名至少为3个字符",
				maxlength:"用户名至多为32个字符",
				remote: "用户名已存在",
			},
			password:{
				required:"必须填写密码",
				minlength:"密码至少为3个字符",
				maxlength:"密码至多为32个字符",
			},
		},

	});

    var errormsg="";

    function checkUsername() {
        var vusername = $('#username').val();
		console.log(vusername);
		var reg =  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,30})+$/;
		if(!reg.test(vusername)){
			errormsg="请输入正确的邮箱";
			$('#usernamemsg').html(errormsg);
		}else{
			errormsg = '';
			$('#usernamemsg').html('');
		}	 
     }
     window.checkUsername = checkUsername;

     function checkPassword(){
		var value = $('#password').val();
		var reg = /^[a-zA-Z0-9]{1,32}$/;
		if(!reg.test(value)){
			errormsg="请输入正确的密码";
			$('#passwordmsg').html(errormsg);	
		}else{
			errormsg = '';
			$('#passwordmsg').html('');	
		}	
	}

    window.checkPassword = checkPassword;


    function doLogin(){
		 checkUsername();
		 if (errormsg != ''){
			alert(errormsg);
			return false;
		 }
         checkPassword();
		 if (errormsg != '') {
			alert(errormsg);
			return false;
		 }
		 alert("请输入正确密码");
        return false;
		// if(errormsg!=''){
		// 	alert("校验未通过"+errormsg);
		// 	return false;
		// }else{
        //     alert("请输入正确密码");
        //     return false;
		// }       
    }

    window.doLogin = doLogin;
});

</script>
 
<style scoped>
html {
    height: 100%;
}

body {
    height: 100%;
}

.login-pag {
    /* margin-top: 5%; */
    /* height: 100%;
    width: 100%; 
    text-align: center;
    margin:5% auto 0 auto;
    background-image: url("./../assets/images/bg.png"); */
    /* color: #fff;
    height:100%;
    width: 100%;
  background-image: url("./../assets/images/bg.png");
  background-repeat: repeat;
  background-size: 100% auto;
  padding-bottom: 1.667vw;
  padding-top: 6.083vw;
  text-align: center;
  position: absolute; */

     height: 100%;
    width: 100%;
    background-image: url("./../assets/images/home/jz_bg_1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    padding-bottom: 1.667vw;
    padding-top: 6.083vw;
    text-align: center;
    color: #fff;
    padding-top: 10%;
}


.login-wrapper {
    color: #fff;
    border-radius: 15px;
    width: 358px;
    height: 588px;
    border-radius: 15px;
    padding: 0 50px;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
}

.header {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    line-height: 200px;
    color: #fff;
}

body {
	background: #f8f8f8;
	font-family: "Microsoft YaHei", "Microsoft JhengHei", STHeiti, MingLiu;
	text-align: center;
	color: #fff
}

.login-container {
	margin: 10% auto 0 auto
}

.register-container {
	margin: 6% auto 0 auto
}

@media (min-width:670px) {
	.login-container {
		margin: 8% auto 0 auto;
	}
}

h1 {
	font-size: 30px;
	font-weight: 700;
	text-shadow: 0 1px 4px rgba(0, 0, 0, .2)
}

form {
	position: relative;
	width: 305px;
	margin: 15px auto 0 auto;
	text-align: center
}

input {
	width: 270px;
	height: 42px;
	line-height: 42px;
	margin-top: 25px;
	padding: 0 15px;
	background: #2d2d2d;
	background: rgba(45, 45, 45, .15);
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #3d3d3d;
	border: 1px solid rgba(255, 255, 255, .15);
	-moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .1);
	-o-transition: all .2s;
	-moz-transition: all .2s;
	-webkit-transition: all .2s;
	-ms-transition: all .2s
}

input:-moz-placeholder {
	color: #fff
}

input:-ms-input-placeholder {
	color: #fff
}

input::-webkit-input-placeholder {
	color: #fff
}

input:focus {
	outline: 0;
	-moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset, 0 2px 7px 0 rgba(0, 0, 0, .2)
}

button {
	cursor: pointer;
	width: 300px;
	height: 44px;
	margin-top: 25px;
	padding: 0;
	background: rgba(6, 127, 228, 0.71);
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	border: 0;
	-moz-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .25) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	font-family: "Microsoft YaHei", Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .1);
	-o-transition: all .2s;
	-moz-transition: all .2s;
	-webkit-transition: all .2s;
	-ms-transition: all .2s
}

button:hover {
	-moz-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2)
}

button:active {
	-moz-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 15px 30px 0 rgba(255, 255, 255, .15) inset, 0 2px 7px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .1) inset, 0 1px 4px 0 rgba(0, 0, 0, .1);
	border: 0 solid #016FCB
}

.connect {
	margin-top: 18px;
	width: 100%;
	font-size: 14px;
	text-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    color: #fff;
}

.connect p {
	position: relative;
	/* left: -140%; */
	top: 0;
	margin-top: 20px;
    color: #fff;
}

.connect a {
	display: inline-block;
	width: 32px;
	height: 35px;
	margin-top: 15px;
	-o-transition: all .2s;
	-moz-transition: all .2s;
	-webkit-transition: all .2s;
	-ms-transition: all .2s
}

.alert {
	width: 310px;
	height: 200px;
	background: #000;
	position: absolute;
	top: -40%;
	left: 50%;
	margin: -101px 0 0 -151px
}

.alert h2 {
	height: 40px;
	padding-left: 8px;
	font-size: 14px;
	background: #FF0543;
	text-align: left;
	line-height: 40px
}

.alert .alert_con {
	background: #fff;
	height: 160px
}

.alert .alert_con p {
	color: #000;
	line-height: 90px
}

.alert .alert_con .btn {
	padding: 3px 10px;
	color: #fff;
	cursor: pointer;
	background: #72D1FF;
	border: 1px solid #72D1FF;
	border-radius: 4px
}

.alert .alert_con .btn:hover {
	background: #4FB2EF;
	border: 1px solid #4FB2EF;
	border-radius: 4px
}

/* Supersized - Fullscreen Slideshow jQuery Plugin  Version : 3.2.7 */
* {
	margin: 0;
	padding: 0
}

body {
	background: #111;
	height: 100%
}

img {
	border: none
}

#supersized-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 0;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	text-indent: -999em;
}

#supersized {
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	overflow: hidden;
	z-index: -999;
	height: 100%;
	width: 100%
}

#supersized img {
	width: auto;
	height: auto;
	position: relative;
	display: none;
	outline: 0;
	border: none
}

#supersized.speed img {
	-ms-interpolation-mode: nearest-neighbor;
	image-rendering: -moz-crisp-edges
}

#supersized.quality img {
	-ms-interpolation-mode: bicubic;
	image-rendering: optimizeQuality
}

#supersized li {
	display: block;
	list-style: none;
	z-index: -30;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #111
}

#supersized a {
	width: 100%;
	height: 100%;
	display: block
}

#supersized li.prevslide {
	z-index: -20
}

#supersized li.activeslide {
	z-index: -10
}

#supersized li.activeslide img,
#supersized li.prevslide img {
	display: inline
}

#supersized img {
	max-width: none !important
}

.box-line {
	height: 0;
	overflow: hidden;
	border-top: 1px #c2c2c2 solid;
	margin-top: 20px;
}

.register-tis {
	line-height: 42px;
	margin: 15px auto 0px;
	padding: 0 15px;
	border: 1px solid rgba(255, 255, 255, 0.38);
	background: rgba(45, 45, 45, 0.33);
	border-radius: 6px;
}

label.error {
	display: block;
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	border-radius: 0px 0px 5px 5px;
	width: 300px;
	height: 34px;
	border: 1px solid rgba(255, 255, 255, .15);
	line-height: 31px;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1) inset;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .1);
	background: rgba(245, 26, 26, 0.81);
	-moz-border-radius: 6px;
	margin: 0 auto;
}

</style>

