<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from './views/Home'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
@media screen and (min-height: 750px) {
  .recharge {
    height: 100vh;
  }
}

/*@media screen and(min-width: 1024px){*/

/*} !*>=1024的设备*!*/
/*@media screen and(min-width: 1100px) {*/

/*} !*>=1100的设备*!*/
/*@media (min-width: 1280px) {*/

/*}*/
/*@media screen and(min-width: 1366px) {*/

/*}*/
/*@media screen and(min-width: 1440px) {*/

/*}*/
/*@media screen and(min-width: 1680px) {*/

/*}*/
/*@media screen and(min-width: 1920px) {*/

/*}*/
/*@media screen and (max-width: 800px) {*/

/*}*/

/*@media screen and (max-width: 1046px) {*/

/*}*/
/*@media screen and (max-width: 1100px) and (min-width: 1046px) {*/

/*}*/
/*@media screen and (max-width: 1150px) and (min-width: 1101px) {*/

/*}*/
/*@media screen and (max-width: 1250px) and (min-width: 1150px){*/

/*}*/
/*@media screen and (min-width: 1250px) and (max-width: 1305px) {*/

/*}*/
/*@media screen and (min-width: 1305px) and (max-width: 1400px) {*/

/*}*/

/*@media screen and (min-height: 900px) {*/
/*  .section-content {*/
/*    zoom: 1.0*/
/*  }*/
/*  !*.recharge-content {*!*/
/*  !*  zoom: 0.7;*!*/
/*  !*  !*transform-origin:top left;*!*!*/
/*  !*  !*transform: scale(0.8)*!*!*/
/*  !*}*!*/
/*}*/

/*@media screen and (min-height: 820px) and (max-height: 900px) {*/
/*  .section-content {*/
/*    zoom: 0.9*/
/*  }*/
/*  .recharge-content {*/
/*    zoom: 0.8;*/
/*    !*transform-origin:top left;*!*/
/*    !*transform: scale(0.8)*!*/
/*  }*/
/*}*/

/*@media screen and (max-height: 820px) {*/
/*  .section-content {*/
/*    zoom: 0.8*/
/*  }*/
/*  .recharge-content {*/
/*    zoom: 0.7*/
/*  }*/
/*}*/

/*@media screen and (max-height: 720px) {*/
/*  .section-content {*/
/*    zoom: 0.7*/
/*  }*/
/*  .recharge-content {*/
/*    zoom: 0.6*/
/*  }*/
/*}*/

/*@media screen and (max-height: 540px) {*/
/*  .section-content {*/
/*    zoom: 0.6*/
/*  }*/
/*  .recharge-content {*/
/*    zoom: 0.5*/
/*  }*/
/*}*/

/*@media screen and (min-height: 900px) {*/
/*  .middle-wrapper {*/
/*    zoom: 1.0*/
/*  }*/
/*}*/
/*@media screen and (min-height: 820px) and (max-height: 900px) {*/
/*  .middle-wrapper {*/
/*    zoom: 0.9*/
/*  }*/
/*}*/

/*@media screen and (max-height: 820px) {*/
/*  .middle-wrapper {*/
/*    zoom: 0.8*/
/*  }*/
/*}*/
/*@media screen and (max-height: 720px) {*/
/*  .middle-wrapper {*/
/*    zoom: 0.7*/
/*  }*/
/*}*/
/*@media screen and (max-height: 540px) {*/
/*  .middle-wrapper {*/
/*    zoom: 0.6*/
/*  }*/
/*}*/

/*@media screen and (min-width: 1250px) and (max-width: 1400px) {*/
/*  .footer {*/
/*    zoom: 0.9*/
/*  }*/
/*}*/
/*@media screen and (max-width: 1250px) and (min-width: 1101px) {*/
/*  .footer {*/
/*    zoom: 0.8*/
/*  }*/
/*}*/
/*@media screen and (max-width: 1101px) and (min-width: 1030px) {*/
/*  .footer {*/
/*    zoom: 0.7*/
/*  }*/
/*}*/

/*@media screen and (max-width: 1030px) and (min-width: 1024px) {*/
/*  .footer {*/
/*    zoom: 0.7*/
/*  }*/
/*}*/

/*@media screen and (max-width: 1024px) {*/

/*}*/
/*
/*    */
/*      .middle-wrapper*/
/*      zoom 0.8*/
/*     */
/*        .middle-wrapper*/
/*        zoom 0.7*/
/*        */
/*          .middle-wrapper*/
/*          zoom 0.6*/
/*          */
/*            .footer*/
/*            zoom 0.9*/
/*            */
/*              .header*/
/*              zoom 0.9*/
/*              .footer*/
/*              zoom 0.8*/
/*              */
/*                .header*/
/*                zoom 0.8*/
/*                .footer*/
/*                zoom 0.7*/
/*                @media screen and (max-width: 1030px) and (min-width: 1024px)*/
/*                  .header*/
/*                  zoom 0.8*/
/*                  .footer*/
/*                  zoom 0.6*/
/*                  @media screen and (max-width: 1024px)*/
/*                    .header*/
/*                    zoom 0.8*/
/*                    .footer*/
/*                    zoom 0.6*/

.page-container {
  width: 100%;
  padding-top: 70px;
}

body {
  background: black;
  margin: 0;
    #app {
        min-height: 100%;
    }

}

.flex {
  display: flex;
}
.container {
  position: relative;
}
</style>
