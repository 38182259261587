<template>
  <div class="intro-page">
    <!--    container-->
    <section id="introduction_1" class="introduction">
      <div class="introduction_1_content">
        <img src="./../../assets/images/home/title1_bg.png" alt="紫色光晕" class="introduction_title1_bg">
        <div class="section-left">
          <!--          欢迎标语-->
          <div class="slogan"></div>
          <div class="down_btns" v-show="true">
            <div class="down_btn_group">
              <img class="down_btn android_btn"
                   @mouseover="downCode.android = false"
                   @mouseleave="downCode.android = false"
                   src="./../../assets/images/home/android_qr.png" alt="安卓">
              <img v-if="downCode.android" class="down_code android_code" src="./../../assets/images/home/android_qr.png"
                   alt="安卓二维码">
            </div>
            <!-- <div class="down_btn_group">
              <img class="down_btn ios_btn"
                   @mouseover="downCode.ios = true"
                   @mouseleave="downCode.ios = false"
                   src="./../../assets/images/home/ios_btn.png" alt="ios">
              <img v-if="downCode.ios" class="down_code ios_code" src="./../../assets/images/home/iOS.png"
                   alt="ios二维码">
            </div> -->
          </div>
        </div>
        <div class="mobile">
          <img class="mobile-bg" src="./../../assets/images/home/jz_phone_1.png" alt="">
          <!-- <div style="overflow: hidden" class="swiper mobile-pic-content swiper-no-swiping" id="mobile">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in phoneList" :key="index">
                <img class="mobile-pic" :src="item" :alt="`手机内幻灯片${index}`">
              </div>
            </div> -->
          <!-- </div> -->
          <!--          放大镜-->
          <!-- <div class="front-gradient"></div> -->
        </div>
      </div>
    <!-- </section> -->
    <!-- <section id="introduction_2" class="introduction">
      <div class="flex container">
        <div class="introduction_2_left"> -->
          <!--          产品介绍title-->
          <!-- <div class="introduction_2_title"></div> -->
          <!-- <p class="introduction_2_des1">「剧阵剧本杀」APP是真人剧本杀组局拼车聊天平台。</p> -->
          <!--          <div class="introduction_2_des2">-->
          <!--            <p>超多原创剧本、靠谱队友陪你随时开局，飙演技，揭开真相。</p>-->
          <!--            <p>多元宇宙、新奇剧本永远是你探索不完的世界。</p>-->
          <!--          </div>-->
          <!-- <ul class="introduction_2_des3">
            <li><img src="./../../assets/images/home/star.png" alt="">超多原创首发剧本，各种题材任选</li>
            <li><img src="./../../assets/images/home/start1.png" alt="">业内首创：局内小房间私聊讨论，更加沉浸</li>
            <li><img src="./../../assets/images/home/star.png" alt="">剧本评分机制，不错过任何一个优秀的原创剧本</li>
            <li><img src="./../../assets/images/home/start1.png" alt="">熟人好友组局开车，共享美好周末</li>
            <li><img src="./../../assets/images/home/star.png" alt="">同城优秀玩家随时组队，线上线下一起互动。</li>
          </ul> -->
        <!-- </div> -->
        <!--        飞人-->
        <!-- <div class="introduction_2_right"></div> -->
      <!-- </div> -->
    <!-- </section> -->
    <!-- <section id="introduction_3">
      <div class="introduction_section">
        <div class="introduction_3_title"></div>
        <h5 class="introduction_3_subTitle">可能是线上剧本杀沉浸感天花板</h5>

        <div class="introduction_3_swiper">
          <div class="controller">
            <img src="./../../assets/images/home/Saly-2.png" class="sally" id="sally2" alt="幻灯片飞天小人">
          </div>
          <div class="swiper-container" id="gallery">
            <div class="swiper-wrapper">
              <div class="swiper-slide gallery-item"
                   :style="`background-image: url('${item}')`"
                   v-for="(item, index) in swiperList" :key="index">
                <div class="cover" v-show="activeSlide !== index"></div> -->
                <!--               <img style="width: 100%" :src="item" :alt="`页面中间轮播图${index}`">-->
              <!-- </div> -->
            <!-- </div> -->
            <!--           <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>-->
          <!-- </div> -->
        <!-- </div> -->
        <!--       幻灯片 -->
        <!--        <div id="certify">-->
        <!--          <div class="controller">-->
        <!--            <img src="./../../assets/images/home/Saly-2.png" class="sally" id="sally2" alt="幻灯片飞天小人">-->
        <!--          </div>-->
        <!--          <div class="swiper-container introduction_3_swiper" id="gallery">-->
        <!--            <div class="swiper-wrapper">-->
        <!--              <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">-->
        <!--                <img :src="item" :alt="`手机内幻灯片${index}`">-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      <!-- </div> -->
    <!-- </section> -->
    <!-- <section id="introduction_4" class="introduction section-content"> -->
      <!-- <div class="introduction_4_section container flex"> -->
        <!--      <div class="introduction introduction_4_section section-content">-->
        <!-- <div> -->
          <!-- <div class="introduction_4_title"></div> -->
          <!-- <div class="row section-content">
            <div class="introduction_4_pc"></div>
          </div>

        </div>
        <div>
          <div class="introduction_4_des1">《剧阵》剧本投稿须知</div>
          <h5 class="introduction_4_des2">联系我们</h5>
          <div class="introduction_4_des3">
            <p>客服邮箱：kefu@jzjbs.com</p>
            <p>账户封禁申诉渠道：kefu@jzjbs.com</p>
            <p>商务合作：kefu@jzjbs.com</p>
          </div>
          <h5 class="introduction_4_des2">关注公众号</h5>
          <img src="./../../assets/images/home/code2.png" class="introduction_4_code" alt="公众号">
        </div>
      </div> -->
      <footer>
        <div class="footer">
          <p></p>
          <p>本公司社交产品仅适合18岁以上用户</p>
          <p>
             版权所有:Copyright️2021北京多优乐科技有限公司  地址：北京市朝阳区广顺北大街33号院1号楼(五、十、十一层)1单元7828号
          </p>
      <a style="color: #999999;">增值电信业务经营许可证：京B2-20182020</a>
		  <a style="padding-left:10px; color: #999999;" href="http://www.beian.gov.cn/portal/recordQuery">公安备案号：11010502045358</a>
		  <a style="padding-left:10px; color: #999999;" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备18029974号</a>
      <a style="padding-left:10px; color: #999999;">ISBN 978-7-498-12556-9</a>
        </div>
      </footer>
    </section>

  </div>
</template>

<script>
import phone1 from './../../assets/images/home/phone1.png'
import phone2 from './../../assets/images/home/phone2.png'
import phone3 from './../../assets/images/home/phone3.png'
import phone4 from './../../assets/images/home/phone4.png'
import swiper1 from './../../assets/images/swipper/1.png'
import swiper2 from './../../assets/images/swipper/2.png'
import swiper3 from './../../assets/images/swipper/3.png'
import swiper4 from './../../assets/images/swipper/4.png'
import swiper5 from './../../assets/images/swipper/5.png'
import './style.css';
// import './media.css';

export default {
  name: "ProductIntroduction",
  data() {
    return {
      activeSlide: 0,
      phoneList: [
        phone1,
        phone2,
        phone3,
        phone4,
      ],
      swiperList: [
        swiper1,
        swiper2,
        swiper3,
        swiper4,
        swiper5,
      ],
      downCode: {
        android: false,
        ios: false,
      }
    }
  },
  mounted() {
    this.getPath();
    this.$nextTick(() => {
      const that = this
      new window.Swiper('#mobile', {
        loop: true,
        speed: 300,
        autoplay: {
          delay: 3000
        },
      })
      new Swiper('#gallery', {
        effect: 'coverflow',
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,//slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: 100,//每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 150,//slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 1,//depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
          slideShadows: false,//开启slide阴影。默认 true。
        },
        on: {
          slideChange () {
            that.activeSlide = this.realIndex;
          }
        }
      })
      // new window.Swiper('#certify .swiper-container', {
      //   watchSlidesProgress: true,
      //   slidesPerView: 'auto',
      //   centeredSlides: true,
      //   loop: true,
      //   loopedSlides: 5,
      //   autoplay: true,
      //   on: {
      //     progress: function () {
      //       for (var i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i);
      //         var slideProgress = this.slides[i].progress;
      //         var modify = 1;
      //         if (Math.abs(slideProgress) > 1) {
      //           modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
      //         }
      //         var translate = slideProgress * modify * 260 + 'px';
      //         var scale = 1 - Math.abs(slideProgress) / 5;
      //         var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
      //         slide.transform('translateX(' + translate + ') scale(' + scale + ')');
      //         slide.css('zIndex', zIndex);
      //         slide.css('opacity', 1);
      //         if (Math.abs(slideProgress) > 3) {
      //           slide.css('opacity', 0);
      //         }
      //       }
      //     },
      //     setTransition: function (transition) {
      //       for (var i = 0; i < this.slides.length; i++) {
      //         var slide = this.slides.eq(i)
      //         slide.transition(transition);
      //       }
      //
      //     }
      //   }
      //
      // })
    })

  },
  watch: {
    '$route': 'getPath'
  },
  methods: {
    goAnchor() {
      let anchor = this.$el.querySelector('#introduction_4')
      document.documentElement.scrollTop = anchor.offsetTop
    },
    getPath() {
      const anchor = this.$route.query.anchor
      if (anchor) {
        this.$nextTick(() => {
          this.goAnchor()
        })
      } else {
        document.documentElement.scrollTop = 0
      }
    }
  }
}
</script>

<style scoped>
/* 上下浮动动画 start*/
.controller {
  position: absolute;
  width: 24.583vw;
  height: 32.25vw;
  top: -2.5vw;
  z-index: 100;
  left: 40%;
  -webkit-animation: bounce-down 1.6s linear infinite;
  animation: bounce-down 1.6s linear infinite;
}

.sally {
  position: absolute;
  top: 0;
}

@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-4px);
  }
  50%, 100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(4px);
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(-0.333vw);
  }
  50%, 100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0.333vw);
  }
}

/* 上下浮动动画 end*/

/*  放大镜 转动 start */
@keyframes spin2 {
  from {
    transform: translate(50%, 12.5vw) rotate(0turn) translate(-50%, -12.5vw) translate(50%, 50%) rotate(1turn) translate(-50%, -50%)
  }
  to {
    transform: translate(50%, 12.5vw) rotate(1turn) translate(-50%, -12.5vw) translate(50%, 50%) rotate(0turn) translate(-50%, -50%)
  }
}

.front-gradient {
  animation: spin2 5s infinite linear;
  z-index: 10;
}

/*  放大镜 转动 end */

/* 小火煎 start */
.introduction_2_right {
  animation: bounce-down 2s infinite linear;
}

/* 小火煎 edn */

/* 摇一摇手机start */
.introduction_4_pc {
  -webkit-animation: bounce-left-Right 1.6s linear infinite;
  animation: bounce-left-Right 1.6s linear infinite;
}

@-webkit-keyframes bounce-left-Right {
  25% {
    -webkit-transform: translate(-0.333vw, 0.333vw);
  }
  50%, 100% {
    -webkit-transform: translateX(0);
  }
  75% {
    -webkit-transform: translate(0.333vw, -0.333vw);
  }
}

/* 摇一摇手机end */
</style>
